import ReactDOM from 'react-dom/client';
import React from 'react';
import './style';
import 'regenerator-runtime/runtime';
import App from './components/App';

const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
const OuterPlayer = React.lazy(() => import('../SpotifyWebPlayback/OuterPlayer'));
const YoutubeWithWrapper = React.lazy(() => import('../Youtube/YoutubeWithWrapper'));

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <Suspense
            fallback={<h1>Loading...</h1>}
          >
            <OuterPlayer />
          </Suspense>
        } />
        <Route path="/youtube" element={
          <Suspense
            fallback={<h1>Loading...</h1>}>
            <YoutubeWithWrapper />
          </Suspense>
        } />
      </Routes>
    </BrowserRouter>
  )
}